var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-chip',{staticClass:"text-capitalize custom--chip",staticStyle:{"border-radius":"10px !important"},attrs:{"color":_vm.color ||
  ([
    'developing',
    'processing',
    'approving',
    'draft',
    'pending',
    'medium',
    'applying',
    'pending_activation',
  ].includes(_vm.state.toLowerCase())
    ? 'custom-light-orange custom-deep-orange--text'
    : [
      'approved',
      'signed',
      'executing',
      'execution_stage',
      'active',
      'in_progress',
      'paid',
      'intro_call',
      'shortlisted',
      'published',
      'public',
      'low',
      'verified',
    ].includes(_vm.state.toLowerCase())
      ? 'custom-light-green custom-deep-green--text'
      : [
        'rejected',
        'declined',
        'unpaid',
        'terminated',
        'closed',
        'unsuccessful',
        'high',
      ].includes(_vm.state.toLowerCase())
        ? 'custom-light-red primary--text'
        : ['accepted', 'completed', 'shortlisted'].includes(_vm.state.toLowerCase())
          ? 'teal lighten-5 teal--text'
          : ['available', 'partially paid', 'queued'].includes(_vm.state.toLowerCase())
            ? 'custom-light-blue custom-deep-blue--text'
            : ['terminated', 'unpublished', 'filled'].includes(_vm.state.toLowerCase())
              ? 'grey lighten-5 grey--text'
              : ['scoping', 'archived', 'scoping_stage'].includes(_vm.state.toLowerCase())
                ? 'brown lighten-5 brown--text text--darken-3'
                : ['proposal', 'proposal_stage'].includes(_vm.state.toLowerCase())
                  ? 'amber lighten-5 amber--text text--darken-2'
                  : 'grey lighten-3'),"small":"","label":""}},[(_vm.icon)?_c(_setup.Icon,{staticClass:"mr-1",attrs:{"size":"10","name":_vm.icon,"type":_vm.iconType}}):_vm._e(),_vm._v(" "+_vm._s(_vm.state.replace("_", " "))+" ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }